<template>
  <div class="slideout" :class="{active: show}">
    <div class="panel">
      <div>
        <a type="button" class="delete is-larg" @click="$parent.previewMap">close</a>
      </div>
      <div class="content">
        <section class="section">
          Map
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'show'
  ]
}
</script>
