<template>
  <div>
    <div v-if="getUser">
      <a @click="likeAction" v-if="postLiked"><b-icon class="has-text-danger" icon="heart" size="lg" /></a>
      <a @click="likeAction" v-else><b-icon class="has-text-danger" icon="heart-outline" size="lg" /></a>
      <span class="has-text-danger is-size-7"> {{ userCount }} إعجاب</span>
    </div>
    <div v-else>
      <b-icon v-if="userCount > 0" class="has-text-danger" icon="heart" size="lg" />
      <b-icon v-else class="has-text-danger" icon="heart-outline" size="lg" />
      <span class="has-text-danger is-size-7"> {{ userCount }} إعجاب</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      likeId: null,
      postLiked: false,
      userCount: 0
    }
  },
  created () {
    this.getLikes()
  },
  methods: {
    getLikes: function () {
      let params = {
        appid: this.$store.state.config.XbAppID,
        type: 'data',
        request: 'km_likes',
        getby: `postId:${this.post}`
      }
      this.XbortGetRequest('', params, result => {
        if (result.result) {
          this.likeId = result.data[0]._id
          if (result.data[0].users && result.data[0].users.length > 0) {
            if (result.data[0].users.includes(this.getUser)) {
              this.postLiked = true
            }
            this.userCount = result.data[0].users.length
          } else {
            this.postLiked = false
            this.userCount = 0
          }
        }
      })
    },
    likeAction: function () {
      // Only if post has already been liked
      let method = 'put'
      let params = {}
      if (this.postLiked) {
        // Remove like
        // update likes
        method = 'delete'
        params = {
          appid: this.$store.state.config.XbAppID,
          type: 'data',
          request: 'km_likes',
          id: this.likeId,
          record: `users-${this.getUser}`
        }
      } else {
        method = 'post'
        console.log(this.likeId)
        if (this.likeId) {
          params = {
            appid: this.$store.state.config.XbAppID,
            type: 'data',
            request: 'km_likes',
            id: this.likeId,
            record: 'users',
            data: this.getUser
          }
        } else {
          params = {
            appid: this.$store.state.config.XbAppID,
            type: 'data',
            request: 'km_likes',
            data: JSON.stringify({
              postId: this.post,
              users: [this.getUser]
            })
          }
        }
      }
      this.XbortRequest('', method, params, result => {
        if (result.result) {
          // update likes
          this.getLikes()
          this.successSave()
        } else {
          this.errorMessage(result.data)
        }
      })
    }
  },
  props: [
    'post'
  ],
  computed: {
    ...mapGetters([
      'getUser'
    ])
  }
}
</script>
